import React from "react";

export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.918326762239!2d29.073519276600823!3d37.791953771981106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c73f2815329f45%3A0xbf3e1fd1b0ad6472!2zRUvEsE4gQsSwWUU!5e0!3m2!1sen!2str!4v1732978295016!5m2!1sen!2str&hl=tr"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-sm-12 col-md-6 contact-info">
              <div className="contact-item">
                <h3>İLETİŞİM BİLGİLERİ</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Adres
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Telefon
                  </span>{" "}
                  <a
                    href={`tel:${props.data ? props.data.phone : "#"}`}
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                  >
                    {props.data ? props.data.phone : "loading"}
                  </a>
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> E-posta
                  </span>{" "}
                  <a
                    href={`mailto:${props.data ? props.data.email : "#"}`}
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                  >
                    {props.data ? props.data.email : "loading"}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={
                        props.data && props.data.whatsapp
                          ? props.data.whatsapp
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        props.data && props.data.facebook
                          ? props.data.facebook
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        props.data && props.data.instagram
                          ? props.data.instagram
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        props.data && props.data.email
                          ? `mailto:${props.data.email}`
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        props.data && props.data.phone
                          ? `tel:${props.data.phone}`
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-phone"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 Ekin Biye.</p>
        </div>
      </div>
    </div>
  );
};
